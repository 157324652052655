
import { Options, Vue } from 'vue-class-component'
import editinformation from '@/components/Memberportal/EditYourInformation/index.vue'

@Options({
  components: {
    editinformation
  }
})
export default class EditYourInformation extends Vue {}
