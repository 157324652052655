import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "edituser" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_editinformation = _resolveComponent("editinformation")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_editinformation)
  ]))
}