export enum SharePermissionType {
  Nej = <any>'nej',
  Telefonnummer = <any>'telefonnummer',
  Email = <any>'email',
  Begge = <any>'begge'
}

export enum ClubRoleType {
  Medlem = <any>'medlem',
  Aktiv_medlem = <any>'aktiv_medlem',
  Passiv_medlem = <any>'passiv_medlem',
  Aeresmedlem = <any>'aeresmedlem',
  Formand = <any>'formand',
  Kasserer = <any>'kasserer',
  Holdkaptajn = <any>'holdkaptajn',
  Bestyrelsesmedlem = <any>'bestyrelsesmedlem',
  Traener = <any>'traener'
}

export enum SysMemberWheelchairFriendlyType {
  Ja = <any>'Ja',
  Nej = <any>'Nej'
}

export interface SysMemberBypostId {
  id: null
  bypost_postnummer: number
  bypost_by: string
  created_at: string
  updated_at: string
}

export interface SysMemberBrugerId {
  id: null
  blocked: boolean
  username: string
  email: string
  firstname: string
  lastname: string
  phone: number
  lastActive: string
  resetPasswordToken: string
  usrgroup: number
  created_at: string
  updated_at: string
}

/* export interface SysMemberBrugerId {
  id: null
  bruger_status: boolean
  bruger_brugernavn: string
  bruger_email: string
  bruger_fornavn: string
  bruger_efternavn: string
  bruger_telefon: string
  bruger_sidste_login: string
  bruger_token: string
  // brugergruppe: string
  bruger_brugergruppe_id: number
  created_at: string
  updated_at: string
} */

export interface SysMemberUserId {
  id: null
  blocked: boolean
  username: string
  email: string
  firstname: string
  lastname: string
  phone: string
  lastActive: string
  confirmationToken: string
  // brugergruppe: string
  usrgroup: number
  created_at: string
  updated_at: string
  role: number
}

export interface SysMemberRegionId {
  id: null
  region_navn: string
  published_at: string
  created_at: string
  updated_at: string
}

export interface SysMemberKoen {
  id: null
  Koen: string
  published_at: string
  created_at: string
  updated_at: string
}

export interface SysMemberClubsId {
  id: null
  klubber_status: boolean
  klubber_klubnavn: string
  klubber_logo: string
  klubber_vejnavn: string
  bypost_id: number
  region_id: number
  klubber_telefonnummer: string
  klubber_email: string
  klubber_saldo: number
  klubber_klubbeskrivelse: string
  klubber_aaben: string
  klubber_baner: number
  klubber_hjemmekampe: number
  klubber_etableringsaar: string
  klubber_koerestolsvenlig: SysMemberWheelchairFriendlyType
  klubber_ansvarlig_id: number
  klubber_slug: string
  klubber_kontingent_slut: string
}

export interface SysMemberClubs {
  id: null
  klubber_status: boolean
  klubber_klubnavn: string
  klubber_logo: string
  klubber_vejnavn: string
  bypost_id: SysMemberBypostId
  region_id: SysMemberRegionId
  klubber_telefonnummer: string
  klubber_email: string
  klubber_saldo: number
  klubber_klubbeskrivelse: string
  klubber_aaben: string
  klubber_baner: number
  klubber_hjemmekampe: number
  klubber_etableringsaar: string
  klubber_koerestolsvenlig: SysMemberWheelchairFriendlyType
  klubber_ansvarlig_id: number
  klubber_slug: string
}

export interface SysMemberCompetitionRegistrationId {
  id: null
  staevne_tilmelding_status: boolean
  staevner_id: number[]
  staevne_raekker_id: number
  published_at: string
  created_at: string
  updated_at: string
}

export interface SysMemberTeamId {
  id: null
  hold_status: number
  hold_holdnavn: string
  // klubber_id: number
  raekke_id: number
  published_at: string
  created_at: string
  updated_at: string
}

export default interface SysMember {
  id: null
  medlem_status: boolean
  medlem_vejnummer: string
  medlem_foedselsdag: string
  medlem_begyndelse: string
  /* medlem_rolle: number */
  medlem_licens: number
  medlem_licens_slut: string
  medlem_sidste_kamp: string
  medlem_paradart: boolean
  medlem_karantaene: boolean
  medlem_karantaene_dato: string
  // medlem_karantaene_periode: string
  medlem_karantaene_slutdato: string
  medlem_klubrolle: ClubRoleType
  medlem_visoplysninger: SharePermissionType
  bypost_id: SysMemberBypostId
  // bruger_id: SysMemberBrugerId
  user_id: SysMemberUserId
  region_id: SysMemberRegionId
  koen_id: SysMemberKoen
  klubber_id: SysMemberClubsId
  hold_id: SysMemberTeamId
  staevne_tilmelding_id: SysMemberCompetitionRegistrationId[]
  published_at: string
  created_at: string
  updated_at: string
}
