import SysClub from './SysClub'
import SysMember from './SysMember'

export enum SysClubChangeFriendlyType {
  Afventer = <any>'afventer',
  Godkend = <any>'godkend',
  Afvis = <any>'afvis'
}
export default interface SysClubChange {
  id: null
  klubskifte_sidstekamp: string
  medlem_id: SysMember
  klubskifte_gmlklub: SysClub
  klubskifte_nyklub: SysClub
  klubskifte_status: boolean
  klubskifte_god_afv: SysClubChangeFriendlyType
  create_at: string
  updated_at: string
}
