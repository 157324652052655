import http from '../http-common'
import { APIBaseFunctions, APIDataSliceObj, defaultAPILimit } from '@/components/Utility/APIBase'

class ClubsDataService extends APIBaseFunctions {
  private cachedClubsDataSliceObj: APIDataSliceObj = {
    slicemode: 0,
    start: 0,
    limit: defaultAPILimit,
    page: 0,
    pagesize: 0,
    totalcount: 0
  }

  getAll (sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
    const currentDataSliceObj = ClubsDataService.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedClubsDataSliceObj }, dataSliceObj)

    if (sortingOrder.length > 0) {
      return http.get(`/klubbers?_sort=${sortingOrder}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }

    return http.get(`/klubbers?_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
  }

  get (id: string) {
    return http.get(`/klubbers/${id}`)
  }

  getBySlug (slug: string) {
    return http.get(`/klubbers/?klubber_slug=${slug}`)
  }

  getCount (extraCountParameter = '') {
    return http.get('/klubbers/count' + (extraCountParameter.length > 0 ? `?${extraCountParameter}` : ''))
  }

  create (data: any) {
    return http.post('/klubbers', data)
  }

  update (id: string, data: any) {
    return http.put(`/klubbers/${id}`, data)
  }

  delete (id: string) {
    return http.delete(`/klubbers/${id}`)
  }

  deleteAll () {
    return http.delete('/klubbers')
  }

  findByClubStatus (status: boolean, sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
    let sortingOrderParameter = ''
    const currentDataSliceObj = ClubsDataService.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedClubsDataSliceObj }, dataSliceObj)

    if (sortingOrder.length > 0) {
      sortingOrderParameter = `&_sort=${sortingOrder}`
    }

    return http.get(`/klubbers?klubber_status=${status}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
  }

  findBySearchTerm (status: boolean, ignoreStatus: boolean, searchTerm: string, sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
    let retVal
    let sortingOrderParameter = ''
    const currentDataSliceObj = ClubsDataService.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedClubsDataSliceObj }, dataSliceObj)
    searchTerm = searchTerm.trim()

    if (sortingOrder.length > 0) {
      sortingOrderParameter = `&_sort=${sortingOrder}`
    }

    if (!isNaN(Number(searchTerm))) {
      // Handle post number in search term. In order to change back to handle telephone number in search term uncomment one line and comment out the other line
      if (ignoreStatus) {
        // retVal = http.get(`/klubbers?klubber_telefonnummer_contains=${searchTerm}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
        retVal = http.get(`/klubbers?bypost_id.bypost_postnummer_contains=${searchTerm}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      } else {
        // retVal = http.get(`/klubbers?klubber_status=${status}&klubber_telefonnummer_contains=${searchTerm}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
        retVal = http.get(`/klubbers?klubber_status=${status}&bypost_id.bypost_postnummer_contains=${searchTerm}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      }
    } else if (searchTerm.indexOf('@') >= 0) {
      // Handle email address in search term
      if (ignoreStatus) {
        retVal = http.get(`/klubbers?klubber_email_contains=${searchTerm}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      } else {
        retVal = http.get(`/klubbers?klubber_status=${status}&klubber_email_contains=${searchTerm}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      }
    } else {
      // Do a search in names for the search term
      if (ignoreStatus) {
        retVal = http.get(`/klubbers?_where[_or][0][klubber_klubnavn_contains]=${searchTerm}&_where[_or][1][klubber_klubbeskrivelse_contains]=${searchTerm}&_where[_or][2][bypost_id.bypost_by_contains]=${searchTerm}&_where[_or][3][klubber_vejnavn_contains]=${searchTerm}&_where[_or][4][region_id.region_navn]=${searchTerm}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      } else {
        retVal = http.get(`/klubbers?klubber_status=${status}&_where[_or][0][klubber_klubnavn_contains]=${searchTerm}&_where[_or][1][klubber_klubbeskrivelse_contains]=${searchTerm}&_where[_or][2][bypost_id.bypost_by_contains]=${searchTerm}&_where[_or][3][klubber_vejnavn_contains]=${searchTerm}&_where[_or][4][region_id.region_navn]=${searchTerm}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      }
    }

    return retVal
  }
}

export default new ClubsDataService()
