import { Vue } from 'vue-class-component'
import { useRoute } from 'vue-router'
import { Watch } from 'vue-property-decorator'
import CommonFunctions from '@/components/Utility/Common'
import BaseFunctions from '@/components/Utility/Base'
import MembersDataService from '@/services/MembersDataService'
import ClubsDataService from '@/services/ClubsDataService'
import ClubChangeDataService from '@/services/ClubChangeDataService'
import UsersDataService from '@/services/UsersDataService'
import ByPostDataService from '@/services/ByPostDataService'
import { History } from '@/services/HistoryDataService'
import { Products } from '@/services/ProductsDataService'
import SysMember, { SharePermissionType } from '@/types/SysMember'
import SysClub from '@/types/SysClub'
import ByPost from '@/types/ByPost'
import SysClubChange, { SysClubChangeFriendlyType } from '@/types/SysClubChange'
import SysHistory from '@/types/SysHistory'
import AuthService from '@/services/AuthService'

type memberDataType = { memberID: number; firstName: string, lastName: string, userID: number; userName: string, userPassword: string; telephone: string; email: string; address: string, cityPostalNumberID: number, postalNumber: string, city: string, paraDartPlayer: boolean; gender: string; memberStatus: boolean, licenseExpires: string; licenseNumber: number, quarantineStartDate: string, qurantineEndData: string, birthDate: string, clubID: number, clubName: string, clubRole: string, membershipStartDate: string; }
type dataReturnType = { memberData: memberDataType, error: any }

export default class editUser extends Vue {
  private todaysDate = new Date().toISOString().split('T')[0]
  private tempMember: SysMember[] = []
  private tempCityPostal: ByPost[] = []
  private currentCityPostalNumberID = 0
  private MemberId = this.parseUrl()
  private changeClubModal = false
  private changeClubValue = { name: 'Vælg klub', id: 0 }
  private changeClubOptions: { name: string, id: number }[] = [{ name: 'Vælg klub', id: 0 }]
  private requestInProgress: SysClubChange[] = []
  private changeContactInformationModal = false
  private cityPostalNumberInputFocus = 2 // Used to track which watch-tied input fields has the current input focus. Value 1 = city input field is in focus, value 2 = postal code input field is in focus
  private inhibitUpdateCityInfo = false
  memberData: memberDataType = { memberID: 0, firstName: '', lastName: '', userID: 0, userName: '', userPassword: '', telephone: '', email: '', address: '', cityPostalNumberID: 0, postalNumber: '', city: '', paraDartPlayer: false, gender: '', memberStatus: false, licenseExpires: '', licenseNumber: 0, quarantineStartDate: '', qurantineEndData: '', birthDate: '', clubName: '', clubID: 0, clubRole: '', membershipStartDate: '' }
  error: any = null
  sharePermissionsValue = this.parseEnum(SharePermissionType.Nej)
  sharePermissionsOptions = Object.values(SharePermissionType).filter(element => (<string>element).startsWith((<string>element).substring(0, 1).toUpperCase()))
  private seeHistoryModal = false
  private showCompleteHistory = false
  private memberHistory: SysHistory[] = []

  readonly name : string = 'editYourInformation'
  $Message: any
  data (): dataReturnType {
    return {
      error: this.error,
      memberData: this.memberData
    }
  }

  @Watch('changeClubModal')
  onClubModalChange (newVal: boolean) {
    if (!newVal) {
      this.changeClubValue = { name: 'Vælg klub', id: 0 }
    }
  }

  @Watch('changeContactInformationModal')
  onContactModalChange (newVal: boolean) {
    if (!newVal) {
      this.retrieveMemberInformation()
    }
  }

  @Watch('sharePermissionsValue')
  onChange () : void {
    console.log(this.parseEnum(this.sharePermissionsValue))
  }

  public seeHistory () : void {
    this.retrieveMemberHistory()
    this.seeHistoryModal = true
  }

  public retrieveMemberHistory () : void {
    let limit = 10
    if (this.showCompleteHistory) {
      limit = -1
    }
    History.HistoryDataService.getAll('created_at:desc', { slicemode: 0, start: 0, limit: limit, page: 0, pagesize: 0, totalcount: 0 }, `medlem_id.id=${this.memberData.memberID.toString()}`)
      .then((response) => {
        this.memberHistory = response.data
        this.seeHistoryModal = true
      })
      .catch((err) => {
        console.error(err)
      })
  }

  updatePermissions () : void {
    const updateMember = {
      medlem_visoplysninger: this.parseEnum(this.sharePermissionsValue)
    }

    MembersDataService.update(this.memberData.memberID.toString(), updateMember)
      .then((response) => {
        console.log(response.data)
        this.retrieveMemberInformation()
      })
      .catch((err) => {
        console.error(err)
      })
  }

  public cancelRequest (id: number) {
    ClubChangeDataService.delete(id.toString())
      .then((response) => {
        // console.log(response.data)
        this.retrieveClubChangeRequests()
      })
      .catch((err) => {
        console.error(err)
      })
  }

  public requestClubChange () {
    if (this.requestInProgress.length > 0) {
      return this.$Message.warning({ text: 'Du er allerede i gang med et klubskifte' })
    }

    const createRequest = {
      medlem_id: this.memberData.memberID,
      klubskifte_gmlklub: this.memberData.clubID,
      klubskifte_nyklub: this.changeClubValue.id,
      klubskifte_status: true,
      klubskifte_god_afv: SysClubChangeFriendlyType.Afventer
    }
    ClubChangeDataService.create(createRequest)
      .then((response) => {
        // console.log(response.data)
        this.retrieveClubChangeRequests()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  expirationDate (dateString: string | null) : string {
    return (dateString === null ? '01/01/70' : this.toDanishDateString(dateString))
  }

  public validMembership (status: boolean, expirationDate: string | null) : boolean {
    const tempDate = (expirationDate === null ? '1970-01-01' : expirationDate)
    if (status && this.todaysDate > tempDate) {
      return true
    }
    return false
  }

  public capitalize (word: string) : string {
    return word[0].toUpperCase() + word.slice(1)
  }

  public convertClubRoleEnum (enumObj: any) : string {
    enumObj = enumObj.toString().replaceAll('ae', 'æ').replaceAll('_', ' ')
    return this.capitalize(enumObj)
  }

  parseUrl () : number {
    let retVal = 0
    const route = useRoute()
    const memberId = route.params.userSlug.toString().split('-')[0]
    if (Number(memberId) !== undefined) {
      retVal = Number(memberId)
    }
    return retVal
  }

  public checkEmail (email: string) : boolean {
    return BaseFunctions.isEmail(email)
  }

  public checkStrongPassword (password: string) : boolean {
    return CommonFunctions.strongPasswordTest(password)
  }

  public retrieveMemberInformation () {
    // Changed to use the userId from localStorage
    const userString = localStorage.getItem('user')?.toString()
    const userDataObject = (userString !== undefined && userString !== null ? JSON.parse(userString) : null)

    if (userDataObject === null || userDataObject.id === 1) {
      // console.log('ERROR fetching memberData')
      CommonFunctions.redirectLogin()
      return
    }

    MembersDataService.getAll('', null, `user_id.id=${userDataObject.id}`)
      .then((response) => {
        this.tempMember = response.data

        if (this.tempMember.length !== 1) {
          this.$Message.danger({ text: 'Fejl: Noget gik galt' })
          return
        }
        // A rudementary check, if the memberId from the URL, does not match the memberId retrieved via the userId, logout and go to login page
        if (this.MemberId !== Number(this.tempMember[0].id)) {
          AuthService.logout()
          CommonFunctions.redirectLogin()
        }

        this.memberData = {
          memberID: Number(this.tempMember[0].id),
          firstName: this.tempMember[0].user_id.firstname,
          lastName: this.tempMember[0].user_id.lastname,
          userID: Number(this.tempMember[0].user_id.id),
          userName: this.tempMember[0].user_id.username,
          userPassword: '',
          telephone: Number(this.tempMember[0].user_id.phone).toString(),
          email: this.tempMember[0].user_id.email,
          address: this.tempMember[0].medlem_vejnummer,
          cityPostalNumberID: (this.tempMember[0].bypost_id === null ? 0 : Number(this.tempMember[0].bypost_id.id)),
          postalNumber: (this.tempMember[0].bypost_id !== undefined && this.tempMember[0].bypost_id !== null ? (this.tempMember[0].bypost_id.bypost_postnummer !== null ? Number(this.tempMember[0].bypost_id.bypost_postnummer).toString() : '0') : '0'),
          city: (this.tempMember[0].bypost_id !== undefined && this.tempMember[0].bypost_id !== null ? (this.tempMember[0].bypost_id.bypost_by !== null ? this.tempMember[0].bypost_id.bypost_by : '') : ''),
          gender: this.tempMember[0].koen_id.Koen,
          memberStatus: this.tempMember[0].medlem_status,
          licenseExpires: this.tempMember[0].medlem_licens_slut,
          licenseNumber: (this.tempMember[0].medlem_licens !== null ? this.tempMember[0].medlem_licens : 0),
          quarantineStartDate: this.tempMember[0].medlem_karantaene_dato,
          qurantineEndData: this.tempMember[0].medlem_karantaene_slutdato,
          birthDate: this.tempMember[0].medlem_foedselsdag,
          clubID: (this.tempMember[0].klubber_id === null ? 0 : Number(this.tempMember[0].klubber_id.id)),
          clubName: (this.tempMember[0].klubber_id !== null ? this.tempMember[0].klubber_id.klubber_klubnavn : 'Ingen klub'),
          clubRole: this.convertClubRoleEnum(this.tempMember[0].medlem_klubrolle),
          membershipStartDate: this.tempMember[0].medlem_begyndelse,
          paraDartPlayer: this.tempMember[0].medlem_paradart
        }

        if (this.tempMember[0].medlem_visoplysninger === null) {
          this.sharePermissionsValue = this.parseEnum(SharePermissionType.Nej)
        } else {
          this.sharePermissionsValue = this.parseEnum(this.tempMember[0].medlem_visoplysninger)
        }

        this.retrieveClubChangeRequests()
        // console.log(this.memberData)
      })
      .catch((err) => {
        this.error = err
      })
  }

  public parseEnum (typeString: any) : SharePermissionType {
    return <any>SharePermissionType[typeString]
  }

  public retrieveClubChangeRequests () : void {
    ClubChangeDataService.getAll('', null, `medlem_id.id=${this.memberData.memberID.toString()}&klubskifte_status=true`)
      .then((response) => {
        this.requestInProgress = response.data
      })
      .catch((err) => {
        console.error(err)
      })
  }

  public convertUserNameToSlug (id: number, userName: string) : string {
    return id.toString() + '-' + CommonFunctions.slugify(userName)
  }

  public toDanishDateString (dateString: string) : string {
    return new Date(dateString).toLocaleDateString('da-DK', { day: '2-digit', month: '2-digit', year: '2-digit' }).replaceAll('.', '/')
  }

  public changeClub () {
    let tempClubs: SysClub[] = []
    if (!(this.changeClubOptions.length > 1)) {
      ClubsDataService.getAll('klubber_klubnavn:asc', null, 'klubber_status=true')
        .then((response) => {
          tempClubs = response.data

          for (const item of tempClubs) {
            this.changeClubOptions.push({ id: Number(item.id), name: item.klubber_klubnavn })
          }
        })
        .catch((err) => {
          console.error(err)
        })
    }
    this.changeClubModal = true
  }

  public updateContactInformation () {
    if (this.memberData.telephone.length !== 8 || !this.checkEmail(this.memberData.email) || this.memberData.address.length <= 1 || this.memberData.postalNumber.length !== 4 || this.memberData.city.length <= 1) {
      return this.$Message.warning({ text: 'Fejl i et eller flere af felterne' })
    }
    const updateUserData = {
      phone: this.memberData.telephone,
      email: this.memberData.email
    }

    const updateMemberData = {
      medlem_vejnummer: this.memberData.address,
      bypost_id: (this.currentCityPostalNumberID === 0 ? this.memberData.cityPostalNumberID : this.currentCityPostalNumberID)
    }

    UsersDataService.update(this.memberData.userID.toString(), updateUserData)
      .then((response) => {
        // console.log(response.data)
        MembersDataService.update(this.memberData.memberID.toString(), updateMemberData)
          .then((response) => {
            // console.log(response.data)
            this.changeContactInformationModal = false
          })
          .catch((err) => {
            console.error(err)
          })
      })
      .catch((err) => {
        console.error(err)
      })
  }

  public retrieveDateTimeFromServer () : void {
    BaseFunctions.getDatetimeFromServer()
      .then((response) => {
        this.todaysDate = new Date(response.data).toISOString().split('T')[0]
        // this.todaysDate = response.data.split('T')[0]
      })
      .catch((err) => {
        this.error = err
      })
  }

  // Following are code for finding and matching postal number and city
  @Watch('memberData.city')
  onMemberCityChange (cityValue: string) {
    if (this.cityPostalNumberInputFocus === 1 && cityValue !== null) {
      this.updatePostalCodeInputFieldDataFromCityInputFieldData(cityValue)
    }
  }

  @Watch('memberData.postalNumber')
  onMemberPostalNumberChange (postalNumberValue: number, oldFieldPostnummerValue: string) {
    if (this.cityPostalNumberInputFocus === 2 && this.inhibitUpdateCityInfo === false && !isNaN(postalNumberValue)) {
      this.updateCityInputFieldDataFromPostalCodeInputFieldData(postalNumberValue.toString())
    }
  }

  @Watch('cityPostalNumberInputFocus')
  onCityPostalNumberInputFocusChange (fieldInputFocusValue: string) {
    if (Number(fieldInputFocusValue) === 1) {
      if (!isNaN(Number(this.memberData.postalNumber))) {
        this.updateCityInputFieldDataFromPostalCodeInputFieldData(this.memberData.postalNumber)
      }
    } else if (Number(fieldInputFocusValue) === 2) {
      if (this.memberData.city !== null) {
        this.updatePostalCodeInputFieldDataFromCityInputFieldData(this.memberData.city)
        this.updateCityInputFieldDataFromPostalCodeInputFieldData(this.memberData.postalNumber)
      }
    }
  }

  public updatePostalCodeInputFieldDataFromCityInputFieldData (cityData: string) : void {
    if (cityData !== undefined && cityData !== null && cityData.length > 1) {
      ByPostDataService.findByCity(cityData, '', '1')
        .then((response) => {
          this.tempCityPostal = response.data
          if (this.tempCityPostal === undefined || this.tempCityPostal === null || this.tempCityPostal[0] === undefined) {
            this.currentCityPostalNumberID = 0
          } else {
            this.inhibitUpdateCityInfo = true
            this.currentCityPostalNumberID = (this.tempCityPostal[0].id !== null ? Number(this.tempCityPostal[0].id) : 0)
            // console.log('Current bypost Id : ' + this.currentCityPostalNumberID)
            this.memberData.postalNumber = (this.tempCityPostal[0].id !== null ? (this.tempCityPostal[0].bypost_postnummer.toString()) : '')
            this.inhibitUpdateCityInfo = false
          }
        })
        .catch((err) => {
          this.error = err
          // console.log(err)
        })
    }
  }

  public updateCityInputFieldDataFromPostalCodeInputFieldData (postalCodeData: string) : void {
    if (postalCodeData !== undefined && postalCodeData !== null && Number(postalCodeData) > 799 && Number(postalCodeData) < 9999) {
      ByPostDataService.findByPostalnumber(Number(postalCodeData), '', '1')
        .then((response) => {
          this.tempCityPostal = response.data
          if (this.tempCityPostal === undefined || this.tempCityPostal === null || this.tempCityPostal[0] === undefined) {
            this.currentCityPostalNumberID = 0
          } else {
            this.currentCityPostalNumberID = (this.tempCityPostal[0].id !== null ? Number(this.tempCityPostal[0].id) : 0)
            // console.log('Current bypost Id : ' + this.currentCityPostalNumberID)
            this.memberData.city = (this.tempCityPostal[0].id !== null ? (this.tempCityPostal[0].bypost_by) : '')
          }
        })
        .catch((err) => {
          this.error = err
          // console.log(err)
        })
    }
  }

  async mounted () {
    const loggedInStatus = localStorage.getItem('status')
    const loginType = localStorage.getItem('logintype')
    const apiToken = localStorage.getItem('apitoken')
    const userdata = localStorage.getItem('user')

    if (loggedInStatus !== undefined && loggedInStatus !== null && loggedInStatus === true.toString() && loginType !== undefined && loginType !== null && loginType === true.toString() && apiToken !== undefined && apiToken !== null && apiToken.length >= 100 && userdata !== undefined && userdata !== null && !userdata.startsWith('{"id":1,')) {
      this.retrieveMemberInformation()
      this.retrieveDateTimeFromServer()
    } else {
      CommonFunctions.redirectLogin()
    }
  }
}
