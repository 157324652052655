import http from '../http-common'
import { APIBaseFunctions, APIDataSliceObj, defaultAPILimit } from '@/components/Utility/APIBase'

class ClubChangeDataService extends APIBaseFunctions {
  private cachedClubChangeDataSliceObj: APIDataSliceObj = {
    slicemode: 0,
    start: 0,
    limit: defaultAPILimit,
    page: 0,
    pagesize: 0,
    totalcount: 0
  }

  getAll (sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
    const currentDataSliceObj = ClubChangeDataService.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedClubChangeDataSliceObj }, dataSliceObj)

    if (sortingOrder.length > 0) {
      return http.get(`/klubskiftes?_sort=${sortingOrder}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }

    return http.get(`/klubskiftes?_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
  }

  get (id: string) {
    return http.get(`/klubskiftes/${id}`)
  }

  getCount (extraCountParameter = '') {
    return http.get('/klubskiftes/count' + (extraCountParameter.length > 0 ? `?${extraCountParameter}` : ''))
  }

  create (data: any) {
    return http.post('/klubskiftes', data)
  }

  update (id: string, data: any) {
    return http.put(`/klubskiftes/${id}`, data)
  }

  delete (id: string) {
    return http.delete(`/klubskiftes/${id}`)
  }

  deleteAll () {
    return http.delete('/klubskiftes')
  }

  findByStatus (status: boolean, sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
    let sortingOrderParameter = ''
    const currentDataSliceObj = ClubChangeDataService.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedClubChangeDataSliceObj }, dataSliceObj)

    if (sortingOrder.length > 0) {
      sortingOrderParameter = `&_sort=${sortingOrder}`
    }

    return http.get(`/klubskiftes?klubskifte_status=${status}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
  }

  findBySearchTerm (status: boolean, ignoreStatus: boolean, searchTerm: string, sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
    let retVal
    let sortingOrderParameter = ''
    const currentDataSliceObj = ClubChangeDataService.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedClubChangeDataSliceObj }, dataSliceObj)

    if (sortingOrder.length > 0) {
      sortingOrderParameter = `&_sort=${sortingOrder}`
    }

    if (ignoreStatus) {
      retVal = http.get(`/klubskiftes?_where[_or][0][klubskifte_gmlklub.klubber_klubnavn_contains]=${searchTerm}&_where[_or][1][klubskifte_nyklub.klubber_klubnavn_contains]=${searchTerm}&_where[_or][2][klubskifte_god_afv_contains]=${searchTerm}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    } else {
      retVal = http.get(`/klubskiftes?klubskifte_status=${status}&_where[_or][0][klubskifte_gmlklub.klubber_klubnavn_contains]=${searchTerm}&_where[_or][1][klubskifte_nyklub.klubber_klubnavn_contains]=${searchTerm}&_where[_or][2][klubskifte_god_afv_contains]=${searchTerm}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }

    return retVal
  }
}

export default new ClubChangeDataService()
